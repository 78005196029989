<template>
  <PageWithHeader>
    <template #title>
      <t path="holi_2"></t>
    </template>
    <div class="main-body">
      <img class="main-bg" src="/img/events/happyHoli/bg_main@2x.png"/>
      <div class="p-16">
        <t path="holi_10" :args="{ gift: winGift }" v-if="isWin && !isDeliver" class="c-white" style="line-height: 20px;">
          Congratulations, You got the Xiaomi Band!
          We will buy it from Amazon.in and send it to your address below, please fill it asap.
        </t>
        <t path="holi_14" class="c-white" style="line-height: 20px;" v-if="isWin && isDeliver">
          The gift has been shipped to address below,
          it will be delivered within 3 days. If you have any question please contact customer service.
        </t>
        <div :class="['bg-theme ad-wrap mt-16 mb-32 px-16 py-24 c-text',{'c-tip': isDeliver}]" v-if="isWin && isInputAdd">
          <div class="a-w-top df-between">
            <t path="holi_12" class="f-bold f-nm df-middle">Deliver to:</t>
            <div class="edit-btn df-middle bg-background" @click="linkToEditAddress" v-if="!isDeliver">
              <Pic src="events/modify" width="16" height="16"/>
              <t path="holi_13" class="f-md">Edit</t>
            </div>
          </div>
          <p class="mt-12" style="line-height: 20px;">{{ inputAddInfo.recipientAddress }}</p>
        </div>
        <div class="ad-btn df-center mt-12" v-if="isWin && !isInputAdd">
          <t path="holi_11" class="f-bold f-lg" style="color: #770211;" @click="linkToEditAddress">Add the delivery address</t>
        </div>

        <div class="df-center mt-24 img-btn">
          <t class="f-bold c-white t-title" path="holi_3">Gifts</t>
        </div>

        <div class="gifts-wrap py-16">
          <div :class="['gift-item df-middle mb-8 p-16', {'gift-item-check':item.chooseStatus}]" v-for="(item, index) in giftList" :key="index">
            <Pic :src="item.url" width="80" height="80"/>
            <div class="item-right flex-1 pl-24 bg-theme df-col df-center" style="height: 80px;">
              <p class="c-text f-nm f-bold mb-4" style="line-height:24px;">{{ item.giftName }}</p>
              <p class="f-md mb-4" style="line-height:16px;">
                <t class="c-title ta-ti" path="holi_4"></t>
                <money class="f-medium" :v="item.depositAmount" />
              </p>
              <p class="f-md" style="line-height:16px;">
                <t class="c-title ta-ti" path="holi_5">Trade</t>
                <money class="f-medium" :v="item.tradeAmount" />
              </p>
              <Pic src="events/check" v-if="item.chooseStatus" class="check-status" width="24" height="24"/>
            </div>
          </div>
        </div>

        <div class="df-center mt-24 img-btn">
          <t class="f-bold c-white t-title" path="holi_6">My progress</t>
        </div>

        <div class="cur-info df-middle mt-12 px-24 py-16">
          <p class="flex-1">
            <t class="ci-p1 mr-12" path="holi_7">Trade</t>
            <money class="f-medium f-nm" style="color: #00FF1E;" :v="prop('rechargeAmount', data)" />
          </p>
          <p class="flex-1">
            <t class="ci-p1 mr-12" path="holi_8">Trade</t>
            <money class="f-medium f-nm" style="color: #00FF1E;" :v="prop('mtTradeAmount', data)" />
          </p>
        </div>
        <div class="py-24">
          <t as="div" path="holi_9" multiline #="{td}" custom>
            <p class="c-white mb-8" v-for="(p, index) in td" :key="index">{{p}}</p>
          </t>
        </div>
      </div>
    </div>
  </PageWithHeader>
</template>
<script>
import { reactive, shallowRef } from '@vue/reactivity'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import { readRmInfo, readGiftList, readInputInfo } from '@/events/christmas/christmas.api'
import router from '@/router'

export default {
  name: 'Christmas',
  components: { PageWithHeader, Pic },
  setup () {
    const data = reactive({
      rechargeAmount: 0,
      mtTradeAmount: 0,
    })
    const isWin = shallowRef(false)
    const winGift = shallowRef('')
    const isInputAdd = shallowRef(false)
    const isDeliver = shallowRef(false)
    const inputAddInfo = shallowRef()
    const giftList = shallowRef()

    // 获取礼物列表
    const getGiftList = () => {
      readGiftList().then(res => {
        res.forEach(item => {
          if (item.chooseStatus === 1) {
            isWin.value = true
            winGift.value = item.giftName
          }
        })
        giftList.value = res
      })
    }
    getGiftList()
    // 获取用户填写的地址信息
    const getUserAdd = () => {
      readInputInfo().then(res => {
        if (res.recipientName && res.recipientMobile && res.recipientAddress) {
          isInputAdd.value = true
          inputAddInfo.value = res
        }
        if (res.giftStatus >= 2) {
          isDeliver.value = true
        }
      })
    }
    getUserAdd()
    const linkToEditAddress = () => {
      router.push('/address')
    }
    // 查询充值记录
    const getInfo = () => {
      readRmInfo().then(res => {
        data.rechargeAmount = res.rechargeAmount
        data.mtTradeAmount = res.mtTradeAmount
      })
    }
    getInfo()
    return {
      isWin,
      isInputAdd,
      isDeliver,
      inputAddInfo,
      data,
      giftList,
      linkToEditAddress,
    }
  },
}
</script>
<style scoped lang="scss">
.main-body{
  background-color: #4B00A4;
  .main-bg{
    width: 100%;
    height: 120vw;
  }
  .img-btn{
    margin: auto;
    .t-title{
      background: #4B00A4;
      border-radius: 16px;
      border: 3px solid #00FF1E;
      display: flex;
      min-width: 128px;
      height: 32px;
      align-items: center;
      justify-content: center;
    }
  }
  .gifts-wrap{
    margin: auto;
    .gift-item{
      margin: auto;
      margin-bottom: 8px;
      background-image: url(/img/events/happyHoli/bg_gift@2x.png);
      background-color: rgb(222, 1, 65);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      .item-right{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .check-status{
        position: absolute;
        right: 32px;
      }
    }
    .gift-item-check{
      background-image: url(/img/events/happyHoli/chose_bg@2x.png);
    }
  }
  .ad-wrap{
    background-image: url(/img/events/happyHoli/color_border@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 4px;
    background-position: top;
    border-radius: 4px;
    .a-w-top{
      .edit-btn{
        padding: 4px 16px;
        background-color: var(--color-background);
        border-radius: 12px;
      }
    }
  }
  .ad-btn{
    height: 80px;
    padding-top: 14px;
    background-image: url(/img/events/set_btn@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cur-info{
    background-image: url(/img/events/happyHoli/bg_bt@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .ci-p1{
      font-size: 15px;
      font-weight: bold;
      color: #fff;
      line-height: 24px;
    }
  }
}
.ta-ti{
  display: inline-block;
  width: 60px;
}
</style>
